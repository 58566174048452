<template>
  <v-card>
    <v-toolbar dense flat color="green lighten-1" dark>
      <v-btn v-if="isDialog" icon dark @click="dialogClose">
        <v-icon> {{ svg.close }}</v-icon>
      </v-btn>
      <v-toolbar-title>Encodar arquivos</v-toolbar-title>
    </v-toolbar>
    <template v-if="!done">
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="green"> {{ svg.flash }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Selecione os profiles de encode:</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-center ma-6" v-if="loading">
          <v-progress-circular
            width="6"
            size="64"
            indeterminate
            color="green"
          ></v-progress-circular>
        </div>
        <div v-show="!loading">
          <span v-for="(item, key) in profiles" :key="key">
            <v-switch
              class="mr-4 d-inline-block"
              :value="key"
              v-model="selectedProfiles"
              :label="item.title"
            >
            </v-switch>
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogClose">Cancelar</v-btn>
        <v-btn color="success" text @click="submit" :class="{ error: !ids.length }">Upar!</v-btn>
        <v-progress-circular v-if="loading" indeterminate color="green"></v-progress-circular>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>
        <v-icon color="green"> {{ svg.check }}</v-icon>
        Encodes agendados! Acesse <router-link to="/encodes">o painel de encodes</router-link>
        para acompanhar o progresso e definir mais parâmetros.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogClose">Fechar</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from "@mdi/js";

export default {
  name: "EncodeDialog",
  props: {
    isDialog: Boolean,
    ids: Array
  },
  data: function() {
    return {
      selectedProfiles: [],
      profiles: {},
      loading: true,
      done: false,
      error: null,
      svg: {
        check: mdiCheck,
        close: mdiClose
      }
    };
  },
  methods: {
    dialogClose() {
      this.$emit("dialogClose");
    },
    submit() {
      this.loading = true;
      this.axios
        .post("/encodes", { ids: this.ids, profiles: this.selectedProfiles })
        .then(() => {
          this.loading = false;
          this.done = true;
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.notify({ content: err, color: "error" });
        });
    }
  },
  mounted() {
    this.axios.get("/encodes/profiles").then(res => {
      this.profiles = Object.assign(this.profiles, res.data);
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
