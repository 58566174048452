<template>
  <div>
    <v-dialog v-model="unsavedDialog" max-width="400" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Perder alterações?</v-card-title>
        <v-card-text
          >Se você sair desta página, as alterações que você não salvou serão perdidas. Se salvou,
          blz.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="unsavedDialog = false">
            Continuar na página
          </v-btn>
          <v-btn color="red darken-1" text @click="nextRoute()">Sair</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loadingModal">
      <v-progress-circular indeterminate></v-progress-circular>
      <h3 class="d-inline-block ma-2">Carregando links</h3>
    </v-overlay>
    <v-dialog v-model="uploadDialog" max-width="400" transition="dialog-bottom-transition">
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="green"> {{ svg.flash }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Selecione os servidores:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <ServerSwitchList empty-selection @changeSelectedServers="setSelectedPools" />
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="uploadDialog = false">Cancelar</v-btn>
          <v-btn
            color="success"
            text
            @click="uploadMore"
            :class="{ error: !this.selectedPools.length }"
            >Upar!</v-btn
          >
          <v-progress-circular
            v-if="loadingUpload"
            indeterminate
            color="green"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="encodeDialog" max-width="400" transition="dialog-bottom-transition">
      <EncodeDialog
        v-if="encodeDialog"
        :ids="encodeIds"
        is-dialog
        @dialogClose="encodeDialog = false"
      ></EncodeDialog>
    </v-dialog>
    <v-dialog v-if="dialog" v-model="dialog" scrollable transition="dialog-bottom-transition">
      <div>
        <CompletedLinks
          :link-servers="lPools"
          @dialogClose="dialog = false"
          is-dialog
          upload-menu
        ></CompletedLinks>
      </div>
    </v-dialog>
    <ejs-grid
      ref="grid"
      :data-source="tableData"
      :action-failure="actionFailure"
      :enable-persistence="true"
      :allow-sorting="true"
      :allow-paging="true"
      :sort-settings="sortSettings"
      :selection-settings="selectionSettings"
      :page-settings="pageSettings"
      :search-settings="searchSettings"
      :edit-settings="editSettings"
      :toolbar="toolbar"
      :toolbar-click="toolbarClickHandler"
      :data-bound="dataBound"
      :action-begin="actionBegin"
      :row-selected="toggleButtons"
      :row-deselected="toggleButtons"
      :allow-keyboard="false"
      :created="gridLoad"
      :row-data-bound="rowDataBound"
      @cellEdit="isEdited = true"
      grid-lines="None"
      locale="pt"
      class="data-table"
    >
      <e-columns>
        <e-column field="id" :is-primary-key="true" :is-identity="true" />
        <e-column field="local_path" min-width="350" header-text="Nome" />
        <e-column field="size" header-text="Tamanho" :allow-editing="false" />
        <e-column
          field="created_at"
          header-text="Data de Upload"
          text-align="Right"
          :allow-editing="false"
        />
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import { DataManager, WebMethodAdaptor } from "@syncfusion/ej2-data";
import { Toolbar, Page, Search, Sort, Edit, Resize } from "@syncfusion/ej2-vue-grids";
import { mdiFlash } from "@mdi/js";
import CompletedLinks from "../components/CompletedLinks";
import ServerSwitchList from "../components/ServerSwitchList";
import EncodeDialog from "../components/EncodeDialog";
import Vue from "vue";
import { isEmpty } from "lodash";

class TakoWebAdaptor extends WebMethodAdaptor {
  beforeSend(dm, request) {
    //request.withCredentials = true;
    super.beforeSend(dm, request);
    dm.dataSource.headers = [{ Authorization: `Bearer ${Vue.prototype.$keycloak.token}` }]; // setting header
  }
}

export default {
  name: "Files",
  components: { ServerSwitchList, CompletedLinks, EncodeDialog },
  data() {
    return {
      encodeIds: {},
      encodeDialog: false,
      isEdited: false,
      unsavedDialog: null,
      nextRoute: null,
      selectedPools: [],
      loadingUpload: false,
      uploadDialog: false,
      lPools: {},
      loadingModal: false,
      svg: {
        flash: mdiFlash
      },
      dialog: false,
      pageSettings: { pageSizes: [25, 50, 250], pageSize: 25 },
      selectionSettings: {
        type: "Multiple",
        cellSelectionMode: "BoxWithBorder"
      },
      editSettings: {
        allowEditing: true,
        allowDeleting: this.$keycloak.hasRealmRole("moderador"),
        mode: "Batch"
      },
      customerIDRules: {
        required: true,
        minLength: 3
      },
      toolbar: [
        {
          text: "Copiar URL",
          tooltipText: "Copiar URL (CTRL+C)",
          prefixIcon: "e-copy",
          id: "copy"
        },
        this.$keycloak.hasRealmRole("moderador") ? "Delete" : "",
        "Update",
        "Cancel",
        {
          text: "Ver links",
          prefixIcon: "e-open-export",
          id: "generate"
        },
        {
          text: "Upar em mais servidores",
          prefixIcon: "e-open-upload",
          id: "upload_more"
        }
        /*{
          text: "\uD83C\uDF40 Encodar",
          id: "encode"
        }*/
      ],
      sortSettings: {
        columns: [{ field: "created_at", direction: "Descending" }]
      },
      tableData: new DataManager({
        url: `${process.env.VUE_APP_API_URL}/api/files/list`,
        batchUrl: `${process.env.VUE_APP_API_URL}/api/files/batch`,
        adaptor: new TakoWebAdaptor(),
        crossDomain: true
      })
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      this.unsavedDialog = true;
      this.nextRoute = next;
    } else {
      next();
    }
  },
  methods: {
    setSelectedPools(value) {
      this.selectedPools = value;
    },
    actionBegin(arg) {
      if (arg.requestType === "searching") {
        this.$refs.grid.goToPage(1);
      }
    },
    actionFailure(args) {
      const error = args.error[0].error;
      console.log(error);
      this.notify({
        content: `Erro ${error.status}: ${error.statusText}`,
        color: "error",
        timeout: 10000
      });
    },
    uploadMore() {
      this.loadingUpload = true;
      let ids = [];
      this.$refs.grid.getSelectedRecords().forEach(item => ids.push(item.id));
      this.axios
        .post("jobs", {
          ids: ids,
          servers: this.selectedPools
        })
        .then(() => {
          this.loadingUpload = false;
          this.uploadDialog = false;
          this.loadingModal = false;
          this.dialog = false;
          this.generateLinks(this.selectedPools);
        })
        .catch(err => {
          console.log(err);
          this.notify({ content: "Erro ao criar uploads.", color: "error" });
          this.loadingUpload = false;
          this.uploadDialog = false;
          this.loadingModal = false;
          this.dialog = false;
        });
    },
    generateLinks(extraPools = "") {
      this.loadingModal = true;
      let ids = [];
      this.$refs.grid.getSelectedRecords().forEach(item => ids.push(item.id));
      this.axios
        .post("files/links", {
          ids: ids,
          extraServers: extraPools
        })
        .then(res => {
          if (!isEmpty(res.data)) {
            this.lPools = res.data;
          }
          this.loadingModal = false;
          this.dialog = true;
        });
    },
    gridLoad() {
      this.$refs.grid.ej2Instances.searchSettings.key = this.$store.state.searchValue;
      // TODO if wanted, sort by local_path here.
      this.$refs.grid.ej2Instances.clipboardModule.setCopyData = function setCopyData() {
        // TODO move this function to a more global context?
        const sel = this.parent.getSelectedRecords();
        this.clipBoardTextArea.value = "";
        this.copyContent = "";
        sel.forEach(row => {
          this.copyContent += `${row.url}\r\n`;
        });
        const args = {
          data: this.copyContent,
          cancel: false
        };
        this.parent.trigger("beforeCopy", args);
        if (args.cancel) {
          return;
        }
        this.clipBoardTextArea.value = args.data;
        this.copyContent = args.data;
        if (!navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          this.clipBoardTextArea.select();
        } else {
          this.clipBoardTextArea.setSelectionRange(0, this.clipBoardTextArea.value.length);
        }
        this.isSelect = true;
      };
    },
    dataBound() {
      this.toggleButtons();
      this.$refs.grid.autoFitColumns(["local_path"]);
      this.$refs.grid.hideColumns("id");
    },
    rowDataBound(args) {
      if (args.data?.deleted) {
        args.row.classList.add("deleted");
      }
    },
    toolbarClickHandler(args) {
      if (args.item.id === "encode") {
        this.encodeDialog = true;
        let ids = [];
        this.$refs.grid.getSelectedRecords().forEach(item => ids.push(item.id));
        this.encodeIds = ids;
      }
      if (args.item.id === "copy") {
        this.$refs.grid.copy();
      }
      if (args.item.id === "generate") {
        this.generateLinks();
      }
      if (args.item.id === "upload_more") {
        this.uploadDialog = true;
      }
    },
    toggleButtons() {
      let toggle = false;
      if (this.$refs.grid.getSelectedRecords().length) toggle = true;
      this.$refs.grid.ej2Instances.toolbarModule.toolbar.items[1].disabled = !toggle;
      this.$refs.grid.ej2Instances.toolbarModule.enableItems(
        ["copy", "generate", "upload_more", "encode"],
        toggle
      );
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, Edit, Resize]
  },
  computed: {
    searchSettings() {
      return {
        fields: ["local_path"],
        operator: "contains",
        key: this.$store.state.searchValue
      };
    }
  }
};
</script>

<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.scss";
@import "~@syncfusion/ej2-vue-navigations/styles/toolbar/material.scss";
@import "~@syncfusion/ej2-vue-grids/styles/material.scss";

@import "~@syncfusion/ej2-popups/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";

@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-dropdowns/styles/material.css";

.e-open-upload:before {
  content: "\e60f";
}
.e-open-export:before {
  content: "\e711";
}
.e-grid {
  cursor: default;
  .e-toolbar {
    position: fixed;
    top: 48px;
    z-index: 1;
    width: 100% !important;
  }
  .e-gridheader {
    position: fixed;
    top: 90px;
    z-index: 1;
  }
  .e-gridcontent {
    margin-top: 56px;
    margin-bottom: 50px;
  }
  .e-gridpager {
    position: fixed;
    bottom: 0;
  }
  .e-headercell,
  .e-table {
    background-color: transparent;
  }
  .e-gridheader {
    background-color: rgb(255, 255, 255, 0.9);
  }
  .e-altrow {
    background-color: #fbfbfb;
  }
  .e-hiddenrow {
    display: table-row;
    font-style: italic;
    opacity: 0.8;
    .e-rowcell:not(.e-editedbatchcell) {
      background-color: red;
      color: #c08484;
    }
  }
  .e-row:last-child td {
    //border-bottom: transparent;
  }
  tr.deleted {
    font-style: italic;
    opacity: 0.6;
    td:nth-child(2):before,
    td:nth-child(1):before {
      content: "\00274C";
    }
  }
  .e-rowcell {
    font-size: 12px;
    padding: 6px 26px;
  }
  .e-dlg-container {
    position: fixed !important;
    align-items: center;
    justify-content: center;
  }
}
.data-table.e-grid {
  border: none;
  .e-pager {
    border-top: transparent;
  }
}

.e-grid .e-spinner-pane {
  position: fixed;
  z-index: 999;
  top: 0;
  .e-spinner-inner {
    //top: 44px;
  }
}
</style>
